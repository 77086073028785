<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';

	export default {
		name: "MrService",
		extends: Base,
		data() {
			return {
				Name: "MrService",
				mrApi: {},
				api_url: "",
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			onRefreshPage() {
				if (this.$route.params.id) {
					this.row.ms_header.forEach((v, k) => {
						if (Array.isArray(v)) this.$set(this.row.ms_header, k, {})
					});
					this.row.ms_body.forEach((v, k) => {
						if (Array.isArray(v)) this.$set(this.row.ms_body, k, {})
					});
					this.row.ms_dynamic_url.forEach((v, k) => {
						if (Array.isArray(v)) this.$set(this.row.ms_dynamic_url, k, {})
					});
					this.row.ms_dynamic_params.forEach((v, k) => {
						if (Array.isArray(v)) this.$set(this.row.ms_dynamic_params, k, {})
					});
				}
			},
			getApiName(v) {
				let id = v.target.value
				let type = "getApiName"
				BOGen.apirest("/" + this.Name, {
					id: id,
					type: type
				}, (err, resp) => {
					if (resp.error) return Gen.info(resp.message, "danger")
					if (resp.success) {
						this.$set(this, "api_url", resp.data)
					}
				}, "POST")
			},
			slug(str) {
				if (!str) return
				return str.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')
			},
			submitForm(e, callback) {
				if (e && e.btnLoading()) return;
				BOGen.apirest("/" + this.Name, this.row, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) {
						if (err.status == 422) {
							Object.keys(err.responseJSON).forEach((k) => {
								$("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][
									0
								] + "</label>")
							})
						}
						return
					}
					if (resp.error) {
						if (this.errorFormCallback) return this.errorFormCallback(resp);
						if (callback) return callback(resp);
						return Gen.info(resp.message, "danger")
					}
					if (resp.success) {
						if (this.successFormCallback) return this.successFormCallback(resp);
						if (callback) return callback(resp);
						setTimeout(() => {
							$('.modal').modal('hide')
						}, 2000)
						return Gen.info(resp.message, "success", 1000).then(() => {
							this.$router.push({
								name: this.Name
							})
						})
					}
				}, "POST")
			},
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.api'(v) {
				if (!this.$route.params.id) this.search()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List <span v-if="data.total">({{(data.total||0)}})</span></h5>
								</div>
								<div class="col-md-3">
									<select2 name="" v-model="filter.api" :options="mrApi" :object="['ma_id','ma_name']">
										<option value="">-- All API --</option>
									</select2>
								</div>
								<div class="col-md-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
												placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="ms_name"></SortField>
									</th>
									<th>{{fields.ms_ma_id}}</th>
									<th>{{fields.ms_method}}</th>
									<th>{{fields.ma_url}}</th>
									<th>{{fields.ms_is_active}}</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.ms_name}}</td>
									<td>{{v.ma_name}}</td>
									<td>{{v.ms_method}}</td>
									<td v-if="v.ms_method=='POST'">{{(v.ma_url||'-')+'/p/'+v.ms_slug}}</td>
									<td v-if="v.ms_method=='GET'">{{(v.ma_url||'-')+'/g/'+v.ms_slug}}</td>
									<td v-if="v.ms_method=='PUT'">{{(v.ma_url||'-')+'/put/'+v.ms_slug}}</td>
									<td v-if="v.ms_method=='DELETE'">{{(v.ma_url||'-')+'/del/'+v.ms_slug}}</td>
									<td>
										<StatusLabel :status="v.status"></StatusLabel>
									</td>
									<td class="btn-action">
										<router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}"
											v-tooltip="'Edit'"><i class="ti-marker-alt"></i>
										</router-link>
										<a v-if="page.moduleRole('ChangeStatus')" href="javascript:;" class="icon_action"
											@click="changeStatus(k)" v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
										<a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action"
											@click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6">
							<BoField name="ms_name" v-model="row.ms_name"></BoField>
							<div class="grouper">
								<BoField name="ms_api_url" info="service url dari api vendor" v-model="row.ms_api_url"></BoField>
								<div class="form-group mb-0" id="param">
									<label>Dynamic Params</label>
									<div class="param-clone" v-for="(v,k) in row.ms_dynamic_params" :key="k">
										<div class="form-row align-items-center">
											<div class="col-md-5">
												<input type="text" placeholder="Field" v-model="v.field" class="form-control mb-2">
											</div>
											<div class="col-md-5">
												<input type="text" placeholder="Value" v-model="v.value" class="form-control mb-2">
											</div>
											<div class="col-md-2">
												<a @click="row.ms_dynamic_params.push({})" class="btn btn-success btn-sm plus-btn"><i
														class="fas fa-plus mr-0"></i></a>
												<a v-if="row.ms_dynamic_params.length>1" @click="row.ms_dynamic_params.splice(k,1)"
													class="btn btn-danger btn-sm min-btn ml-1"><i class="fas fa-minus mr-0"></i></a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="grouper">
								<BoField name="ms_auth_type">
									<div class="row">
										<div class="col-md-2">
											<radio name="ms_auth_type" v-model="row.ms_auth_type" option="Basic"
												:attr="validation('ms_auth_type')">Basic
											</radio>
										</div>
										<div class="col-md-2">
											<radio name="ms_auth_type" v-model="row.ms_auth_type" option="Bearer">Bearer
											</radio>
										</div>
										<div class="col-md-2">
											<radio name="ms_auth_type" v-model="row.ms_auth_type" option="">Unauthorization
											</radio>
										</div>
									</div>
								</BoField>
								<div class="row">
									<div class="col-md-12">
										<div class="form-group mb-0" id="param">
											<label for="">Parameter Header</label>
											<div class="param-clone" v-for="(v,k) in row.ms_header" :key="k">
												<div class="form-row align-items-center">
													<template v-if="v.sub_field">
														<div class="col-md-12">
															<div class="row">
																<div class="col-md-9">
																	<input type="text" placeholder="Field" v-model="v.field" class="form-control mb-2">
																</div>
																<div class="col-md-3">
																	<a @click="row.ms_header.push({})" v-tooltip="'add field'"
																		class="btn btn-success btn-sm plus-btn"><i class="fas fa-plus mr-0"></i></a>
																	<a @click="row.ms_header.push({field:'',sub_field:[{field:'',value:''}],value:''})"
																		class="btn btn-info btn-sm plus-btn ml-1" v-tooltip="'add field and sub field'"><i
																			class="fas fa-plus mr-0"></i></a>
																	<a v-if="row.ms_header.length>1" @click="row.ms_header.splice(k,1)"
																		class="btn btn-danger btn-sm min-btn ml-1" v-tooltip="'remove'"><i
																			class="fas fa-minus mr-0"></i></a>
																</div>
																<div class="col-md-12" v-for="(v2,k2) in v.sub_field" :key="k2">
																	<div class="row">
																		<div class="col-md-4">
																			<input type="text" placeholder="Sub Field" v-model="v2.field"
																				class="form-control mb-2">
																		</div>
																		<div class="col-md-5">
																			<input type="text" placeholder="Sub value" v-model="v2.value"
																				class="form-control mb-2">
																		</div>
																		<div class="col-md-2">
																			<a @click="v.sub_field.push({})" v-tooltip="'add sub field'"
																				class="btn btn-success btn-sm plus-btn"><i class="fas fa-plus mr-0"></i></a>

																			<a v-if="v.sub_field.length>1" v-tooltip="'remove'"
																				@click="v.sub_field.splice(k2,1)" class="btn btn-danger btn-sm min-btn ml-1"><i
																					class="fas fa-minus mr-0"></i></a>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</template>
													<template v-else>
														<div class="col-md-4">
															<input type="text" placeholder="Field" v-model="v.field" class="form-control mb-2">
														</div>
														<div class="col-md-5" v-if="(v.field||'').sub_field==undefined">
															<input type="text" placeholder="value" v-model="v.value" class="form-control mb-2">
														</div>
														<div class="col-md-3">
															<a @click="row.ms_header.push({})" v-tooltip="'add field'"
																class="btn btn-success btn-sm plus-btn"><i class="fas fa-plus mr-0"></i></a>
															<a @click="row.ms_header.push({field:'',sub_field:[{}],value:''})"
																class="btn btn-info btn-sm plus-btn ml-1" v-tooltip="'add field and sub field'"><i
																	class="fas fa-plus mr-0"></i></a>
															<a v-if="row.ms_header.length>1" v-tooltip="'remove'" @click="row.ms_header.splice(k,1)"
																class="btn btn-danger btn-sm min-btn ml-1"><i class="fas fa-minus mr-0"></i></a>
														</div>
													</template>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<BoField label="URL API Management" info="URL yang akan diberikan kepada client">
								<div class="alert alert-info overflow-auto">
									<span v-if="row.ms_method=='POST'">{{(api_url?api_url:row.ma_url)+'/p/'+slug(row.ms_name)}}</span>
									<span
										v-else-if="row.ms_method=='GET'">{{(api_url?api_url:row.ma_url)+'/g/'+slug(row.ms_name)+'?'+(row.ms_body?row.ms_body.map(e => e.field?e.field+'=[value]':'').join("&"):[])}}</span>
									<span
										v-else-if="row.ms_method=='PUT'">{{(api_url?api_url:row.ma_url)+'/put/'+slug(row.ms_name)}}</span>
									<span
										v-else-if="row.ms_method=='DELETE'">{{(api_url?api_url:row.ma_url)+'/del/'+slug(row.ms_name)}}</span>
									<span v-else>-</span>
								</div>
							</BoField>
						</div>
						<div class="col-sm-6">
							<BoField name="ms_ma_id">
								<select2 name="ms_ma_id" v-model="row.ms_ma_id" @change="getApiName($event)" :options="mrApi"
									:object="['ma_id','ma_name']" v-bind="validation('ms_ma_id')">
									<option value="">--Select API--</option>
								</select2>
							</BoField>
							<div class="grouper">
								<BoField name="ms_method" v-model="row.ms_method" :attr="{readonly:true}">
									<select2 v-model="row.ms_method" :options="{POST:'POST',GET:'GET',PUT:'PUT',DELETE:'DELETE'}">
										<option value="">--Select Method--</option>
									</select2>
								</BoField>
								<div class="form-group mb-0" id="param" v-if="row.ms_method=='POST'">
									<label>Dynamic Header</label>
									<div class="param-clone" v-for="(v,k) in row.ms_dynamic_url" :key="k">
										<div class="form-row align-items-center">
											<div class="col-md-5">
												<input type="text" placeholder="Field" v-model="v.field" class="form-control mb-2">
											</div>
											<div class="col-md-5">
												<input type="text" placeholder="Value" v-model="v.value" class="form-control mb-2">
											</div>
											<div class="col-md-2">
												<a @click="row.ms_dynamic_url.push({})" class="btn btn-success btn-sm plus-btn"><i
														class="fas fa-plus mr-0"></i></a>
												<a v-if="row.ms_dynamic_url.length>1" @click="row.ms_dynamic_url.splice(k,1)"
													class="btn btn-danger btn-sm min-btn ml-1"><i class="fas fa-minus mr-0"></i></a>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group mb-0" id="param">
									<label v-if="row.ms_method == 'GET'" for="">Query Params</label>
									<label v-else for="">Form Data</label>
									<div class="param-clone" v-for="(v,k) in row.ms_body" :key="k">
										<div class="form-row align-items-center">
											<div class="col-md-5">
												<input type="text" placeholder="Field" v-model="v.field" class="form-control mb-2">
											</div>
											<div class="col-md-5">
												<input type="text" placeholder="Value" v-model="v.value" class="form-control mb-2">
											</div>
											<div class="col-md-2">
												<a @click="row.ms_body.push({})" class="btn btn-success btn-sm plus-btn"><i
														class="fas fa-plus mr-0"></i></a>
												<a v-if="row.ms_body.length>1" @click="row.ms_body.splice(k,1)"
													class="btn btn-danger btn-sm min-btn ml-1"><i class="fas fa-minus mr-0"></i></a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<BoField name="ms_is_active">
										<div class="row">
											<div class="col-md-2">
												<radio name="ms_is_active" v-model="row.ms_is_active" option="Y"
													:attr="validation('ms_is_active')">Active
												</radio>
											</div>
											<div class="col-md-2">
												<radio name="ms_is_active" v-model="row.ms_is_active" option="N">
													Inactive
												</radio>
											</div>
										</div>
									</BoField>
								</div>
							</div>
							<div class="row">
								<div class="col-md-8">
									<BoField name="ms_img_tutorial">
										<Uploader :title="'Document View'" name="ms_img_tutorial" type="img_tutorial" uploadType="cropping"
											:param="{thumbnail:true}" v-model="row.ms_img_tutorial">
										</Uploader>
									</BoField>
								</div>
							</div>
						</div>
					</div>
					<hr>
					<div class="text-right">
						<router-link :to="{name:Name}" class="btn">Cancel</router-link>
						<button type="submit" class="btn btn-loading btn-success"><i class="icon-check"></i>Submit</button>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>